import React from "react";

export const tryCatchCreateElement = (elmentFunction, params, callRender = true, debugInfo = {}) => {
    if (callRender) {
    }

    try {
        if (typeof elmentFunction === 'undefined') {
            debugInfo.props = params;
            console.error('elementFunction is undefined', debugInfo);
            return null;
        }

        return React.createElement(elmentFunction, params)
    } catch (error) {
        console.error('Error creating elmentFunction', error, `\n`, debugInfo);
        return null;
    }
}