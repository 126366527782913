'use client'

import React, { useRef, useEffect } from 'react';
import { clsx } from 'clsx';

import ButtonArrowLeft from '@/components/shared/icons/ButtonArrowLeft';
import ButtonArrowRight from '@/components/shared/icons/ButtonArrowRight';

export default function Button({ children, variant, borderedArrowColor = 'bg-white', type, id, onClick, className = '', wrapperClassName = '', style, ...rest }) {
	const buttonRef = useRef(null);
	const leftArrowRef = useRef(null);
	const rightArrowRef = useRef(null);

	useEffect(() => {
		if (buttonRef.current && leftArrowRef.current && rightArrowRef.current) {
			const buttonWidth = buttonRef.current.offsetWidth ?? buttonRef.current.clientWidth;
			const buttonHoverWidth = buttonWidth / 100 * 105;

			leftArrowRef.current.dataset.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			rightArrowRef.current.dataset.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

			const styleELem = document.createElement('style');
			styleELem.innerHTML = `
				.button-wrapper:hover [data-id="${leftArrowRef.current.dataset.id}"] {
					transform: translateX(-${(buttonHoverWidth - buttonWidth) / 2 - 1}px)!important;
				}

				.button-wrapper:hover [data-id="${rightArrowRef.current.dataset.id}"] {
					transform: translateX(${(buttonHoverWidth - buttonWidth) / 2 - 1}px)!important;
				}
			`;

			document.head.appendChild(styleELem);
		}
	}, [buttonRef, leftArrowRef, rightArrowRef]);
	

	if (
		variant !== 'primary' && variant !== 'primary-bordered' &&
		variant !== 'secondary' && variant !== 'secondary-bordered' &&
		variant !== 'tertiary' && variant !== 'tertiary-bordered'
	) {
		console.error('Invalid variant for Button component. Please use one of the following: primary, primary-bordered, secondary, secondary-bordered');
		return null;
	}

	let defaultClasses = 'relative z-10 font-overpass text-base shadow-button uppercase pb-2 pt-2-plus-2px px-6 outline-none focus:outline-none';
	let svg = {
		stroke: '',
		fill: ''
	};
	let isBordered = false;
	switch (variant) {
		case 'primary':
			defaultClasses += ' text-white bg-primary-800 border-2 border-primary-800';
			svg.fill = 'primary-800';
			isBordered = false;
			break;
		case 'primary-bordered':
			defaultClasses += ' text-primary-800 bg-transparent border-2 border-primary-800';
			svg.stroke = 'primary-800';
			isBordered = true;
			break;
		case 'secondary':
			defaultClasses += ' text-primary-800 bg-secondary-100 border-2 border-secondary-100';
			svg.fill = 'secondary-100';
			isBordered = false;
			break;
		case 'secondary-bordered':
			defaultClasses += ' text-secondary-100 bg-transparent border-2 border-secondary-100';
			svg.stroke = 'secondary-100';
			isBordered = true;
			break;
		case 'tertiary':
			defaultClasses += ' text-white bg-tertiary-500 border-2 border-tertiary-500';
			svg.fill = 'tertiary-500';
			isBordered = false;
			break;
		case 'tertiary-bordered':
			defaultClasses += ' text-tertiary-500 bg-transparent border-2 border-tertiary-500';
			svg.stroke = 'tertiary-500';
			isBordered = true;
			break;
		default:
			break;
	}

	return (
		<div className={clsx('button-wrapper flex items-center group', wrapperClassName)}>
			<ButtonArrowLeft {...svg} ref={leftArrowRef} className={`${isBordered ? 'translate-x-0' : ''}`} />

			<button
				type={type}
				id={id}
				ref={buttonRef}
				onClick={onClick}
				className={clsx(defaultClasses, className)}
				style={style}
				{...rest}
			>
				{children}
			</button>

			<ButtonArrowRight {...svg} ref={rightArrowRef} className={`${isBordered ? 'translate-x-0' : ''}`} />
		</div>
	)
}
