'use client';

import ErrorLayout from '@/components/ErrorLayout';

import Image from 'next/image';
import Typography from '@/components/shared/Typography';
import Button from '@/components/shared/forms/Button';

export default function Error({ reset }) {
    return (
        <ErrorLayout>
            <div className="w-screen h-dvh relative">
                <div className='absolute w-full h-full inset-0'>
                    <Image src='/images/footer-background-image.jpeg' alt='404' width={1920} height={1080} className='w-full h-full object-cover object-center' />
                </div>
                <div className='absolute w-full h-full inset-0 flex flex-col gap-y-8 justify-center items-center text-center text-white bg-primary-800 bg-opacity-70'>
                    <Typography variant='h1' hasShadow={true} className='text-white'>
                        Oops, something went wrong
                    </Typography>

                    <Button variant='secondary' onClick={reset}>
                        {'Home'}
                    </Button>
                </div>
            </div>
        </ErrorLayout>
    );
}
